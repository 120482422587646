"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const util_1 = require("../lib/util");
class Hamburger extends HTMLButtonElement {
    constructor() {
        super();
        this.activeClass = null;
        this.inactiveClass = null;
        this.handleClick = this.handleClick.bind(this);
    }
    connectedCallback() {
        this.menu = (0, util_1.getElementByDataAttribute)(this, 'menu');
        this.activeClass = (0, util_1.parseClassName)(this.dataset.activeClass);
        this.inactiveClass = (0, util_1.parseClassName)(this.dataset.inactiveClass);
        this.disabled = false;
        this.setAttribute('aria-controls', this.menu.id);
        this.setAttribute('aria-expanded', 'false');
        this.removeAttribute('aria-hidden');
        this.addEventListener('click', this.handleClick);
    }
    handleClick() {
        const { open } = this;
        this.menu.hidden = open;
        this.setAttribute('aria-expanded', open ? 'false' : 'true');
        if (this.activeClass) {
            if (open) {
                this.classList.remove(...this.activeClass);
            }
            else {
                this.classList.add(...this.activeClass);
            }
        }
        if (this.inactiveClass) {
            if (open) {
                this.classList.add(...this.inactiveClass);
            }
            else {
                this.classList.remove(...this.inactiveClass);
            }
        }
    }
    get open() {
        return !this.menu.hidden;
    }
}
exports.default = Hamburger;
