"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Arrow;
(function (Arrow) {
    Arrow["UP"] = "ArrowUp";
    Arrow["DOWN"] = "ArrowDown";
    Arrow["LEFT"] = "ArrowLeft";
    Arrow["RIGHT"] = "ArrowRight";
})(Arrow || (Arrow = {}));
class DropdownNavigation extends HTMLUListElement {
    constructor() {
        super();
        this.items = [];
        this.links = [];
        this.buttons = [];
        this.menus = [];
        this.clickOnlyMediaQuery = window.matchMedia('(max-width: 767px)');
        this.initializeMenuCallback = this.initializeMenuCallback.bind(this);
        this.initializeControlCallback = this.initializeControlCallback.bind(this);
        this.initializeButtonCallback = this.initializeButtonCallback.bind(this);
        this.initializeItemCallback = this.initializeItemCallback.bind(this);
        DropdownNavigation.instance++;
    }
    connectedCallback() {
        this.items = Array.from(this.children);
        this.links = this.items.map(item => item.querySelector('a'));
        this.buttons = this.items.map(item => item.querySelector('button'));
        this.menus = this.items.map(item => item.querySelector('ul'));
        this.menus.forEach(this.initializeMenuCallback);
        this.links.forEach(this.initializeControlCallback);
        this.buttons.forEach(this.initializeControlCallback);
        this.buttons.forEach(this.initializeButtonCallback);
        this.items.forEach(this.initializeItemCallback);
        this.addEventListener('mouseenter', () => this.cancelHide());
        this.addEventListener('mouseleave', () => this.scheduleHide());
        document.body.addEventListener('click', (event) => {
            if (event.target && !this.contains(event.target)) {
                this.hideAll();
            }
        });
    }
    show(index) {
        this.hideAll();
        this.setShown(index, true);
    }
    hide(index) {
        this.setShown(index, false);
    }
    hideAll() {
        for (let i = 0; i < this.items.length; i++) {
            this.hide(i);
        }
    }
    setShown(index, shown) {
        const menu = this.menus[index];
        if (!menu) {
            return;
        }
        const item = this.items[index];
        const link = this.links[index];
        const button = this.buttons[index];
        const { activeClass } = this;
        menu.hidden = !shown;
        if (link) {
            link.setAttribute('aria-expanded', shown ? 'true' : 'false');
        }
        if (button) {
            button.setAttribute('aria-expanded', shown ? 'true' : 'false');
        }
        if (item && activeClass) {
            if (shown) {
                item.classList.add(...activeClass);
            }
            else {
                item.classList.remove(...activeClass);
            }
        }
    }
    scheduleHide() {
        if (this.clickOnly) {
            return;
        }
        this.hideTimer = window.setTimeout(() => this.hideAll(), 1500);
    }
    cancelHide() {
        if (this.hideTimer !== undefined) {
            clearTimeout(this.hideTimer);
        }
    }
    get namespace() {
        return this.id || `menu-${DropdownNavigation.instance}`;
    }
    get activeClass() {
        const { activeClass } = this.dataset;
        if (!activeClass) {
            return null;
        }
        return activeClass.split(/\s+/);
    }
    get clickOnly() {
        return this.clickOnlyMediaQuery.matches;
    }
    initializeMenuCallback(menu, index) {
        if (!menu) {
            return;
        }
        menu.id = `${this.namespace}-submenu-${index + 1}`;
    }
    initializeControlCallback(control, index) {
        const menu = this.menus[index];
        if (!control || !menu) {
            return;
        }
        control.setAttribute('aria-controls', menu.id);
        control.setAttribute('aria-expanded', 'false');
    }
    initializeItemCallback(item, index) {
        if (!item) {
            return;
        }
        item.addEventListener('mouseenter', () => {
            if (this.clickOnly) {
                return;
            }
            this.show(index);
        });
        item.addEventListener('focusin', () => this.show(index));
        item.addEventListener('keydown', (event) => {
            if (event.key === Arrow.RIGHT) {
                DropdownNavigation.advanceFocus(this.links, index + 1);
                event.preventDefault();
            }
            else if (event.key === Arrow.LEFT) {
                DropdownNavigation.advanceFocus(this.links, index - 1);
                event.preventDefault();
            }
            else if (event.target instanceof HTMLAnchorElement && this.menus[index]) {
                const links = Array.from(item.querySelectorAll('a'));
                const linkIndex = links.indexOf(event.target);
                if (event.key === Arrow.DOWN) {
                    DropdownNavigation.advanceFocus(links, linkIndex + 1);
                    event.preventDefault();
                }
                else if (event.key === Arrow.UP) {
                    DropdownNavigation.advanceFocus(links, linkIndex - 1);
                    event.preventDefault();
                }
            }
        });
    }
    initializeButtonCallback(button, index) {
        const link = this.links[index];
        if (!button || !link) {
            return;
        }
        button.setAttribute('aria-label', `Expand ${link.textContent} menu`);
        button.addEventListener('click', () => this.show(index));
    }
    static advanceFocus(elements, index) {
        const element = elements[index];
        if (!element) {
            return;
        }
        element.focus();
    }
}
exports.default = DropdownNavigation;
DropdownNavigation.instance = 0;
