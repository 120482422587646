"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseClassName = exports.getElementByDataAttribute = void 0;
function getElementByDataAttribute(element, attribute) {
    const id = element.dataset[attribute];
    if (!id) {
        throw new Error(`The ${attribute} attribute is not defined.`);
    }
    const target = document.getElementById(id);
    if (target === null) {
        throw new Error(`Undefined element ${id}.`);
    }
    return target;
}
exports.getElementByDataAttribute = getElementByDataAttribute;
function parseClassName(className) {
    if (!className) {
        return null;
    }
    return className.split(/\s+/);
}
exports.parseClassName = parseClassName;
